import { useRef, useState, useContext } from 'react';

import { NavLink } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography
} from '@mui/material';

import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';

import { AuthContext } from '../../../../contexts/JWTAuthContext';
import { Logout } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.common.black};
        display: block;`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${theme.palette.common.black}`
);

function getInitials(firstName, lastName) {
  // Extract the first character from each name and convert to uppercase
  const firstInitial = firstName ? firstName[0].toUpperCase() : '';
  const lastInitial = lastName ? lastName[0].toUpperCase() : '';

  // Concatenate the initials
  return `${firstInitial}${lastInitial}`;
}

function HeaderUserbox() {

  const {first_name, last_name, uid, role, logout } = useContext(AuthContext);
  const initials = getInitials(first_name, last_name);
  const translatedRole = (role === 'root')? 'superAdmin':'admin';

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = (): void => {
    logout();
  }

  return (
    <>
      <UserBoxButton ref={ref} onClick={handleOpen}>
        <Avatar variant="circular" alt={first_name + " " + last_name}>
          {initials}
        </Avatar>
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1">
              {first_name + " " + last_name}
            </UserBoxLabel>
            <UserBoxDescription variant="body2">
              <FormattedMessage id={translatedRole} />
            </UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} color='primary' />
        </Hidden>
      </UserBoxButton>
      <Popover
        disableScrollLock={true}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar variant="circular" alt={first_name + " " + last_name}>
            {initials}
          </Avatar>{" "}
          <UserBoxText>
            <UserBoxLabel variant="body1">
              {first_name + " " + last_name}
            </UserBoxLabel>
            <UserBoxDescription variant="body2">
              <FormattedMessage id={translatedRole} />
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          <ListItem
            color='primary'
            to={"/admins/profile/" + uid}
            component={NavLink}
            onClick={handleClose}
          >
            <AccountBoxTwoToneIcon fontSize="small" />
            <ListItemText primary={<FormattedMessage id="myProfile" />} />
          </ListItem>
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button color="primary" variant="contained" fullWidth onClick={handleLogout}>
            <Logout sx={{ mr: 1 }} />
            <FormattedMessage id="signOut" />
          </Button>
        </Box>
      </Popover>
    </>
  );
}
  
export default HeaderUserbox;
