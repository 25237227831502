export const userCodes = {
    en: {
        cu409: 'The chosen username already exists!',
        actu501: 'You are not autorized to activate certain users',
        actu200: 'Users activated successfully',
        delusr200: 'Users deleted successfully',
        delusr501: 'Could not delete users',
        deldev200: 'Device deleted successfully',
        deldev501: 'Could not delete device',
        cusr501: 'The chosen team does not exist',
        cusr502: 'User not allowed to be created on this team!',
        cusr200: 'Users created successfully!',
        cusr409: 'User already exists! Chose a different e-mail or identifier',
        urst422: 'This password was already used recently - Please choose new password',
    },
    pt: {
        cu409: 'O usuário já existe!',
        actu501: 'Você não está autorizado a ativar certos usuários',
        actu200: 'Usuários ativados com sucesso',
        delusr200: 'Usuários removidos com sucesso',
        delusr501: 'Não foi possível remover usuários',
        deldev200: 'Dispositivo removido com sucesso',
        deldev501: 'Não foi possível remover dispositivo',
        cusr501: 'O time escolhido não existe!',
        cusr502: 'O usuário não pode ser criado neste time',
        cusr200: 'Usuários criados com sucesso',
        cusr409: 'Usuário já existe! Escolha um e-mail ou identificador diferente',
        urst422: 'Senha já usada - Favor escolher nova senha',
    },
    es: {
        cu409: '¡El nombre de usuario elegido ya existe!',
        actu501: 'No está autorizado para activar ciertos usuarios',
        actu200: 'Usuarios activados con éxito',
        delusr200: 'Usuarios eliminados con éxito',
        delusr501: 'No se pudo eliminar a los usuarios',
        deldev200: 'Dispositivo eliminado con éxito',
        deldev501: 'No se pudo eliminar el dispositivo',
        cusr501: 'El equipo elegido no existe',
        cusr502: '¡No se permite crear el usuario en este equipo!',
        cusr200: 'Usuarios creados con éxito',
        cusr409: '¡El usuario ya existe! Elija un e-mail o identificador diferente',
        urst422: 'Esta contraseña ya se utilizó recientemente - Por favor, elija una nueva contraseña',
    }
};
