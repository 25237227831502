export const createAssociationCodes = {
    en: {
        asso200: 'Association created successfully',
        asso403: 'Admin not authorized to create association',
        asso404: 'One of the teams in association does not exist',
    },
    pt: {
        asso200: 'Associação criada com sucesso',
        asso403: 'Administrador não autorizado a criar associação',
        asso404: 'Um dos times na associação não existe',
    },
    es: {
        asso200: 'Asociación creada con éxito',
        asso403: 'Administrador no autorizado para crear la asociación',
        asso404: 'Uno de los equipos en la asociación no existe',
    }
};

export const editAssociationCodes = {
    en: {
        easso200: 'Association modified successfully',
        easso400: 'Invalid team list',
        easso403: 'Admin not authorized to edit association',
        easso404: 'Association does not exist',
    },
    pt: {
        easso200: 'Associação modificada com sucesso',
        easso400: 'Lista de times inválida',
        easso403: 'Administrador não autorizado a editar associação',
        easso404: 'Associação não existe',
    },
    es: {
        easso200: 'Asociación modificada con éxito',
        easso400: 'Lista de equipos no válida',
        easso403: 'Administrador no autorizado para editar la asociación',
        easso404: 'La asociación no existe',
    }
};

export const deleteAssociationCodes = {
    en: {
        dasso200: 'Association deleted successfully',
        dasso403: 'Admin not authorized to delete association',
        dasso404: 'Association does not exist',
    },
    pt: {
        dasso200: 'Associação removida com sucesso',
        dasso403: 'Administrador não autorizado a remover associação',
        dasso404: 'Associação não existe',
    },
    es: {
        dasso200: 'Asociación eliminada con éxito',
        dasso403: 'Administrador no autorizado para eliminar la asociación',
        dasso404: 'La asociación no existe',
    }
};