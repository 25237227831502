export const createTeamCodes = {
    en: {
        ct200: 'Team created successfully!',
    },
    pt: {
        ct200: 'Time criado com sucesso!',
    },
    es: {
        ct200: '¡Equipo creado con éxito!',
    }
};

export const editTeamProfileCodes = {
    en: {
        et200: 'Team profile modified successfully',
        et404: 'Team profile not found',
    },
    pt: {
        et200: 'Perfil de time modificado com sucesso',
        et404: 'Perfil de time não encontrado',
    },
    es: {
        et200: 'Perfil del equipo modificado con éxito',
        et404: 'Perfil del equipo no encontrado',
    }
};

export const deleteTeamCodes = {
    en: {
        delt200: 'Teams removed successfully',
        delt403: 'You are not allowed to delete teams',
        delt409: `Team does not exist`,
    },
    pt: {
        delt200: 'Administradores removidos com sucesso',
        delt403: 'Não é permitido remover times',
        delt409: `Time não existe`,
    },
    es: {
        delt200: 'Equipos eliminados con éxito',
        delt403: 'No tiene permiso para eliminar equipos',
        delt409: 'El equipo no existe',
    }
};

export const editDomainsCodes = {
    en: {
        etd200: 'White-list domains modified successfully',
        etd400: 'Bad password',
    },
    pt: {
        etd200: 'Domínios pre-aprovados modifiecados com sucesso',
        etd400: 'Senha incorreta',
    },
    es: {
        etd200: 'Dominios preaprobados modificados con éxito',
        etd400: 'Contraseña incorrecta',
    }
};

export const deleteDomainsCodes = {
    en: {
        dtd200: 'White-list domains deleted successfully',
        dtd400: 'Bad password',
    },
    pt: {
        dtd200: 'Domínios pre-aprovados removidos com sucesso',
        dtd400: 'Senha incorreta',
    },
    es: {
        dtd200: 'Dominios preaprobados eliminados con éxito',
        dtd400: 'Contraseña incorrecta',
    }
};

export const activateAdminCode = {
    en: {
        acta200: 'Administrators activated successfully',
        acta403: 'You are not autorized to activate certain administrators',
        acta404: 'Admin não encontrado',
    },
    pt: {
        acta200: 'Administradores ativados com sucesso',
        acta403: 'Você não está autorizado a ativar certos administradores',
        acta404: 'Admin não encontrado',
    },
    es: {
        acta200: 'Administradores activados con éxito',
        acta403: 'No está autorizado para activar ciertos administradores',
        acta404: 'Administrador no encontrado',
    }
};

export const blockAdminCode = {
    en: {
        blka200: 'Administrators blocked successfully',
        blka403: 'You are not autorized to block certain administrators',
        blka404: 'Admin não encontrado',
    },
    pt: {
        blka200: 'Administradores bloqueados com sucesso',
        blka403: 'Você não está autorizado a bloquear certos administradores',
        blka404: 'Admin não encontrado',
    },
    es: {
        blka200: 'Administradores bloqueados con éxito',
        blka403: 'No está autorizado para bloquear ciertos administradores',
        blka404: 'Administrador no encontrado',
    }
};

export const editTeamMembershipCodes = {
    en: {
        etm200: 'Team permissions were updated successfully',
        etm403: 'Not allowed to include admin in team',
        etm404: 'Admin not found',
    },
    pt: {
        etm200: 'Permissões de time atualizadas com sucesso!',
        etm403: 'Não é autorizado adicionar este admin em um dos times da lista',
        etm404: 'Admin não encontrado',
    },
    es: {
        etm200: 'Permisos del equipo actualizados con éxito',
        etm403: 'No está autorizado para incluir al administrador en el equipo',
        etm404: 'Administrador no encontrado',
    }
};

export const setTeamSettingsCodes = {
    en: {
        sts200: 'Team settings modified successfully',
    },
    pt: {
        sts200: 'Configurações de time modificadas com sucesso',
    },
    es: {
        sts200: 'Configuraciones del equipo modificadas con éxito',
    }
}