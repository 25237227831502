export const registrationCodes = {
    en: {
        reg200: `A verification e-mail has been sent to the address you provided.
        Please follow the instructions on the e-mail so you can sign in!`,
        reg501: 'Registration is only available when e-mailing is active. Contact Administrator.',
        reg509: 'It was not possible to e-mail the registration details to the administrator. Please check e-mail account status and try again.',
    },
    pt: {
        reg200: `Um e-mail de verificação foi enviado para o endereço que você cadastrou.
        Por favor siga as instruções no e-mail para acessar a plataforma!`,
        reg501: 'O registro só está disponível quando o envio de e-mails está ativo. Por favor contactar administrador.',
        reg509: 'Não foi possível enviar o e-mail de cadastro para o administrador. Por favor verifique o status da conta de e-mail e tente novamente.',
    },
    es: {
        reg200: `Se ha enviado un correo electrónico de verificación a la dirección que proporcionaste.
        ¡Por favor, sigue las instrucciones del correo electrónico para iniciar sesión!`,
        reg501: 'El registro solo está disponible cuando el envío de correos electrónicos está activo. Contacta al administrador.',
        reg509: 'No fue posible enviar los detalles de registro al administrador. Por favor, verifica el estado de la cuenta de correo electrónico y vuelve a intentarlo.',
    }
};

export const resetCodes = {
    en: {
        rst200: 'Password Reset Successful',
        rst401: 'Bad credentials',
        rst404: 'Admin user does not exist',
        rst422: 'Repeated Password - Please choose a password different password',
    },
    pt: {
        rst200: 'Mudança de senha efetuada com sucesso',
        rst401: 'Senha incorreta',
        rst404: 'Administrador não existe',
        rst422: 'Senha repetida - Por favor escolha uma nova senha',
    },
    es: {
        rst200: 'Cambio de contraseña realizado con éxito',
        rst401: 'Credenciales incorrectas',
        rst404: 'El usuario administrador no existe',
        rst422: 'Contraseña repetida - Por favor elige una contraseña diferente',
    }
};

export const authCodes = {
    en: {
        auth401: 'Invalid credentials',
        auth403: 'This admin user is blocked - Please contact administrator',
        auth422: 'This admin user\'s registration is not complete - Please check your e-mail',
    },
    pt: {
        auth401: 'Credenciais inválidas',
        auth403: 'Este usuário adminstrador está bloqueado - Por favor entre em contato com seu administrador!',
        auth422: 'O resgistro deste usuário administrador não foi completado - Por favor verifique seu e-mail!',
    },
    es: {
        auth401: 'Credenciales no válidas',
        auth403: 'Este usuario administrador está bloqueado - Por favor contacta al administrador',
        auth422: 'El registro de este usuario administrador no está completo - Por favor verifica tu correo electrónico',
    }
};