import { registrationCodes, authCodes, resetCodes } from "./publicCodes";
import { createInviteCodes, deleteInviteCodes, resendInviteCodes  } from './inviteCodes';
import { userCodes } from './userCodes';
import { editSettingsCodes, editAppSettingsCodes, editTLSCodes } from "./settingsCodes";
import {
    editTeamProfileCodes,
    createTeamCodes,
    deleteTeamCodes,
    editDomainsCodes,
    setTeamSettingsCodes,
    deleteDomainsCodes
} from './teamCodes';
import {
    createAdminCodes,
    deleteAdminCodes,
    editAdminProfileCodes,
    editTeamMembershipCodes,
    terminateAdminCodes,
    activateAdminCode,
    blockAdminCode
} from "./adminCodes";
import {
    createAssociationCodes,
    editAssociationCodes,
    deleteAssociationCodes } from "./associationCodes";
import {
    createLicenseCodes,
    expireLicenseCodes } from "./licenseCodes";

const genericCodes = {
    en: {        
        net: 'Internal Network Error - Contact Administrator',
        unmapped: 'Unmapped error',
        i200: 'Success',
        i404: 'Not Found',
        i500: 'Internal Server Error',
        i504: 'Internal Server Error',
        i400: 'Bad parameters',
        i422: 'Expired',
        i503: 'Service Unavailable',
        i408: 'Timeout',
        i501: 'Emailing is Disabled - Contact Administrator',
        i509: 'Could not send e-mail',
    },
    pt: {
        net: 'Erro Interno de Comunicação - Entre em contato com suporte',
        unmapped: 'Unmapped error',
        i200: 'Sucesso',
        i404: 'Não Encontrado',
        i500: 'Erro Interno do Servidor',
        i504: 'Erro Interno do Servidor',
        i400: 'Pedido não pode ser atendido',
        i422: 'Expirado',
        i503: 'Serviço Indisponível',
        i408: 'Timeout',
        i501: 'Emails estão disativados - Entre em contato com suporte',
        i509: 'E-mail não pode ser enviado',
    },
    es: {
        net: 'Error interno de la red - Contactar al administrador',
        unmapped: 'Error no mapeado',
        i200: 'Éxito',
        i404: 'No encontrado',
        i500: 'Error interno del servidor',
        i504: 'Error interno del servidor',
        i400: 'Parámetros incorrectos',
        i422: 'Expirado',
        i503: 'Servicio no disponible',
        i408: 'Tiempo de espera agotado',
        i501: 'El envío de correos electrónicos está deshabilitado - Contactar al administrador',
        i509: 'No se pudo enviar el correo electrónico',
      }
};

export const responseCodes = {
    'post:v2/public/register': 'reg',
    'post:v2/public/auth': 'auth',
    'post:v2/public/reset': 'rst',
    'post:v2/public/confirm': 'rst',
    'post:v2/admin': 'ca',
    'put:v2/admin': 'ea',
    'delete:v2/admin': 'dela',
    'put:v2/admin/teams': 'etm',
    'delete:v2/admin/terminate': 'terma',
    'put:v2/admin/activate': 'acta',
    'put:v2/admin/block': 'blka',
    'post:v2/license': 'cl',
    'put:v2/license': 'el',
    'post:v2/association': 'asso',
    'put:v2/association': 'easso',
    'delete:v2/association': 'dasso',
    'post:v2/invite': 'cinv',
    'delete:v2/invite': 'dinv',
    'put:v2/invite': 'rinv',
    'put:v2/settings/general': 'eset',
    'put:v2/settings/messaging': 'eset',
    'put:v2/settings/attachments': 'eset',
    'put:v2/settings/calling': 'eset',
    'put:v2/settings/security': 'eset',
    'put:v2/settings/tls': 'eset',
    'put:v2/settings/apps': 'easet',
    'put:v2/settings/certs': 'tls',
    'put:v2/team': 'et',
    'put:v2/team/domains': 'etd',
    'put:v2/team/settings': 'sts',
    'delete:v2/team/domains': 'dtd',
    'post:v2/user': 'cu',
    'post:v2/user/reset': 'urst',
};

export const responseMessages = {
    en: {
        ...genericCodes.en,
        ...registrationCodes.en,
        ...authCodes.en,
        ...resetCodes.en,
        ...createAdminCodes.en,
        ...deleteAdminCodes.en,
        ...editAdminProfileCodes.en,
        ...editTeamMembershipCodes.en,
        ...terminateAdminCodes.en,
        ...activateAdminCode.en,
        ...blockAdminCode.en,
        ...createLicenseCodes.en,
        ...expireLicenseCodes.en,
        ...createAssociationCodes.en,
        ...editAssociationCodes.en,
        ...deleteAssociationCodes.en,
        ...resendInviteCodes.en,
        ...createInviteCodes.en,
        ...deleteInviteCodes.en,
        ...editSettingsCodes.en,
        ...editAppSettingsCodes.en,
        ...editTLSCodes.en,
        ...editTeamProfileCodes.en,
        ...createTeamCodes.en,
        ...deleteTeamCodes.en,
        ...editDomainsCodes.en,
        ...setTeamSettingsCodes.en,
        ...deleteDomainsCodes.en,
        ...userCodes.en,
    },
    pt: {
        ...genericCodes.pt,
        ...registrationCodes.pt,
        ...authCodes.pt,
        ...resetCodes.pt,
        ...createAdminCodes.pt,
        ...deleteAdminCodes.pt,
        ...editAdminProfileCodes.pt,
        ...editTeamMembershipCodes.pt,
        ...terminateAdminCodes.pt,
        ...activateAdminCode.pt,
        ...blockAdminCode.pt,
        ...createLicenseCodes.pt,
        ...expireLicenseCodes.pt,
        ...createAssociationCodes.pt,
        ...editAssociationCodes.pt,
        ...deleteAssociationCodes.pt,
        ...resendInviteCodes.pt,
        ...createInviteCodes.pt,
        ...deleteInviteCodes.pt,
        ...editSettingsCodes.pt,
        ...editAppSettingsCodes.pt,
        ...editTLSCodes.pt,
        ...editTeamProfileCodes.pt,
        ...createTeamCodes.pt,
        ...deleteTeamCodes.pt,
        ...editDomainsCodes.pt,
        ...setTeamSettingsCodes.pt,
        ...deleteDomainsCodes.pt,
        ...userCodes.pt,
    },
    es: {
        ...genericCodes.es,
        ...registrationCodes.es,
        ...authCodes.es,
        ...resetCodes.es,
        ...createAdminCodes.es,
        ...deleteAdminCodes.es,
        ...editAdminProfileCodes.es,
        ...editTeamMembershipCodes.es,
        ...terminateAdminCodes.es,
        ...activateAdminCode.es,
        ...blockAdminCode.es,
        ...createLicenseCodes.es,
        ...expireLicenseCodes.es,
        ...createAssociationCodes.es,
        ...editAssociationCodes.es,
        ...deleteAssociationCodes.es,
        ...resendInviteCodes.es,
        ...createInviteCodes.es,
        ...deleteInviteCodes.es,
        ...editSettingsCodes.es,
        ...editAppSettingsCodes.es,
        ...editTLSCodes.es,
        ...editTeamProfileCodes.es,
        ...createTeamCodes.es,
        ...deleteTeamCodes.es,
        ...editDomainsCodes.es,
        ...setTeamSettingsCodes.es,
        ...deleteDomainsCodes.es,
        ...userCodes.es,
    }
};