export const createAdminCodes = {
    en: {
        ca200: 'Admin user created successfully!',
        ca403: 'You are now allowed to create an admin on this team!',
        ca404: 'The chosen team does not exist!',
        ca409: 'The chosen admin username already exists!',
        ca501: 'Unable to send user invite - Emailing disabled',
        ca509: 'Invite e-mail to administrator could not be sent - Please check e-mail account status and try again.',
    },
    pt: {
        ca200: 'Usuário administrador criado com sucesso!',
        ca403: 'Você não está autorizado a criar um administrador neste time!',
        ca404: 'O time escolhido não existe!',
        ca409: 'O usuário administrador já existe!',
        ca501: 'Unable to send user invite - Emailing disabled',
        ca509: 'E-mail de convite para o administrador não pode ser enviado - Por favor verifique o status da conta de e-mail e tente novamente.',
    },
    es: {
        ca200: '¡Usuario administrador creado con éxito!',
        ca403: '¡No tiene permiso para crear un administrador en este equipo!',
        ca404: '¡El equipo elegido no existe!',
        ca409: '¡El nombre de usuario del administrador elegido ya existe!',
        ca501: 'No se puede enviar la invitación de usuario - El envío de correos electrónicos está deshabilitado',
        ca509: 'No se pudo enviar el correo electrónico de invitación al administrador - Verifique el estado de la cuenta de correo electrónico y vuelva a intentarlo.',
    }
};

export const editAdminProfileCodes = {
    en: {
        ea200: 'Admin profile modified successfully',
        ea404: 'Admin profile not found',
    },
    pt: {
        ea200: 'Perfil de administrador modificado com sucesso',
        ea404: 'Perfil de administrador não encontrado',
    },
    es: {
        ea200: 'Perfil de administrador modificado con éxito',
        ea404: 'Perfil de administrador no encontrado',
      }
};

export const deleteAdminCodes = {
    en: {
        dela200: 'Admins removed successfully',
        dela403: 'You are not allowed to delete certain admins from this list',
        dela409: `To delete your own account, please request an account 
        termination from your profile options!`,
    },
    pt: {
        dela200: 'Administradores removidos com sucesso',
        dela403: 'Não é permitido remover certos administradores desta lista',
        dela409: `Para remover sua própria conta, por favor use a função 'Encerrar Conta' disponível no seu perfil`,
    },
    es: {
        dela200: 'Administradores eliminados con éxito',
        dela403: 'No está permitido eliminar ciertos administradores de esta lista',
        dela409: `Para eliminar su propia cuenta, por favor solicite la terminación de la cuenta desde las opciones de su perfil.`,
    }
};

export const terminateAdminCodes = {
    en: {
        terma200: 'Admin Account terminated successfully',
        terma403: 'Bad password',
    },
    pt: {
        terma200: 'Conta Administradora encerrada com sucesso',
        terma403: 'Senha incorreta',
    },
    es: {
        terma200: 'Cuenta de administrador terminada con éxito',
        terma403: 'Contraseña incorrecta',
    }
};

export const activateAdminCode = {
    en: {
        acta200: 'Administrators activated successfully',
        acta403: 'You are not autorized to activate certain administrators',
        acta404: 'Admin não encontrado',
    },
    pt: {
        acta200: 'Administradores ativados com sucesso',
        acta403: 'Você não está autorizado a ativar certos administradores',
        acta404: 'Admin não encontrado',
    },
    es: {
        acta200: 'Administradores activados con éxito',
        acta403: 'No está autorizado para activar ciertos administradores',
        acta404: 'Administrador no encontrado',
    }
};

export const blockAdminCode = {
    en: {
        blka200: 'Administrators blocked successfully',
        blka403: 'You are not autorized to block certain administrators',
        blka404: 'Admin não encontrado',
    },
    pt: {
        blka200: 'Administradores bloqueados com sucesso',
        blka403: 'Você não está autorizado a bloquear certos administradores',
        blka404: 'Admin não encontrado',
    },
    es: {
        blka200: 'Administradores bloqueados con éxito',
        blka403: 'No está autorizado para bloquear ciertos administradores',
        blka404: 'Administrador no encontrado',
    }
};

export const editTeamMembershipCodes = {
    en: {
        etm200: 'Team permissions were updated successfully',
        etm403: 'Not allowed to include admin in team',
        etm404: 'Admin not found',
    },
    pt: {
        etm200: 'Permissões de time atualizadas com sucesso!',
        etm403: 'Não é autorizado adicionar este admin em um dos times da lista',
        etm404: 'Admin não encontrado',
    },
    es: {
        etm200: 'Permisos del equipo actualizados con éxito',
        etm403: 'No está autorizado para incluir al administrador en el equipo',
        etm404: 'Administrador no encontrado',
    }
};