import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { themeCreator } from './base';

const ThemeProviderWrapper: React.FC = (props) => {
  const theme = themeCreator('cc');

  return (
    <ThemeProvider theme={theme}>
        {props.children}
    </ThemeProvider>
  );
};

export default ThemeProviderWrapper;