import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from 'src/contexts/JWTAuthContext';

const AuthGuard = ({ children }) => {

  let { uid, logout } = useContext(AuthContext);
  const { pathname } = useLocation();
  const token = localStorage.getItem('accessToken');
  if (token === undefined) {
    logout();
  }
  
  return (
    <>
      {(uid !== '') ? (
        children
      ) : (
        <Navigate replace to="/signin" state={{ from: pathname }} />
      )}
    </>
  );
};

export default AuthGuard;
