export const editSettingsCodes = {
    en: {
        eset200: 'Service settings modified successfully!',
        eset400: 'Invalid settings',
    },
    pt: {
        eset200: 'Configurações do serviço modificadas com sucesso!',
        eset400: 'Configurações inválidas',
    },
    es: {
        eset200: '¡Configuraciones del servicio modificadas con éxito!',
        eset400: 'Configuraciones inválidas',
    }
};

export const editAppSettingsCodes = {
    en: {
        easet200: 'App settings modified successfully!',
        easet400: 'Invalid settings',
    },
    pt: {
        easet200: 'Configurações de aplicativo modificadas com sucesso!',
        easet400: 'Configurações inválidas',
    },
    es: {
        easet200: '¡Configuraciones de la aplicación modificadas con éxito!',
        easet400: 'Configuraciones inválidas',
    }
};

export const editTLSCodes = {
    en: {
        tls200: 'TLS Certificate loaded successfully!',
        tls400: 'Invalid settings',
    },
    pt: {
        tls200: 'Certificado TLS carregado com sucesso!',
        tls400: 'Configurações inválidas',
    },
    es: {
        tls200: '¡Certificado TLS cargado con éxito!',
        tls400: 'Configuraciones inválidas',
    }
};