export const createInviteCodes = {
    en: {
        cinv200: 'Invite created successfully',
        cinv400: 'Insufficient licenses',
        cinv404: 'Invalid license pack',
        cinv409: 'One or more users are already onboard',
        cinv422: 'License pack expired',
    },
    pt: {
        cinv200: 'Convite criado com sucesso',
        cinv400: 'Licenças insuficientes',
        cinv404: 'Pack de licenças inválido',
        cinv409: 'Um ou mais usuários já estão ativos na plataforma',
        cinv422: 'License pack expired',
    },
    es: {
        cinv200: 'Invitación creada con éxito',
        cinv400: 'Licencias insuficientes',
        cinv404: 'Paquete de licencias inválido',
        cinv409: 'Uno o más usuarios ya están a bordo',
        cinv422: 'Paquete de licencias expirado',
    }
};

export const deleteInviteCodes = {
    en: {
        dinv200: 'Invite deleted successfully',
        dinv403: 'Admin not authorized to delete selected invite',
        dinv404: 'Invalid license pack',
        dinv422: 'Expired license pack',
    },
    pt: {
        dinv200: 'Convite removido com sucesso',
        dinv403: 'Administrador não atuorizado a remover convite',
        dinv404: 'Pacote de licença inválido',
        dinv422: 'Pacote de licença expirado',
    },
    es: {
        dinv200: 'Invitación eliminada con éxito',
        dinv403: 'Administrador no autorizado para eliminar la invitación seleccionada',
        dinv404: 'Paquete de licencias inválido',
        dinv422: 'Paquete de licencias expirado',
    }
};

export const resendInviteCodes = {
    en: {
        rinv200: 'Invite resent successfully',
        rinv400: 'Insufficient licenses',
        rinv404: 'Invalid license pack',
        rinv410: 'User already onboarded to the platform',
        rinv422: 'License pack expired',
    },
    pt: {
        rinv200: 'Convite re-enviado com sucesso',
        rinv400: 'Licenças insuficientes',
        rinv404: 'Pack de licenças inválido',
        rinv410: 'Usuário já ativo na plataforma',
        rinv422: 'License pack expired',
    },
    es: {
        rinv200: 'Invitación reenviada con éxito',
        rinv400: 'Licencias insuficientes',
        rinv404: 'Paquete de licencias inválido',
        rinv410: 'El usuario ya está a bordo en la plataforma',
        rinv422: 'Paquete de licencias expirado',
    }
};